import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    { path: '', redirectTo: '/index', pathMatch: 'full' },
    { path: 'index', loadChildren: () => import('./index/index.module').then(mod => mod.IndexModule) },
    { path: 'index/:brickId', loadChildren: () => import('./index/index.module').then(mod => mod.IndexModule) },
    { path: 'open/:brickId', loadChildren: () => import('./open/open.module').then(mod => mod.OpenModule) }


];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
