import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
// import { Configs } from 'src/modal/configs.model';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ConfigService {

    configUrl = '/api/game-lottery/query-trend?code=kralk5&mode=times&screen=30';

    heroesUrl = ''

    posthttpss = 'api/article/list-article';

    constructor(private http: HttpClient) {

    }

    // get
    getHttp(url: string) {
        return this.http.get(url).pipe(
            catchError(this.handleError)
        );
    }

    // http
    postHttp(term: string): Observable<any> {
        term = term.trim();

        const options = term ? { params: new HttpParams().set('name', term) } : {};

        return this.http.get<any>(this.posthttpss, options)
            .pipe(
                catchError(this.handleError)
            );
    }


    // options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        })
    };

    postHeaers(url: any, params: any): Observable<any> {
        // 解析请求参数为
        const urlParams = new URLSearchParams();
        if (typeof params === 'object') {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const element = params[key];
                    urlParams.append(key, element);
                }
            }
            params = urlParams.toString();
        }
        return this.http.post<any>(url, params, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    // error
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }
}
