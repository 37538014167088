import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

export const bricks = [
    { brickCode: 'jndpcdd', birckName: '加拿大28' },
    { brickCode: 'txffc', birckName: '腾讯分分彩' },
    { brickCode: 'hn5fc', birckName: '河内5分彩' }, // 原幸运飞艇
    // { brickCode: 'azxy28', birckName: '澳洲幸运28' },
    // { brickCode: 'bj28', birckName: '北京28' },
    // { brickCode: 'jssc', birckName: '极速赛车' },
    { brickCode: 'kralk28', birckName: '韩国幸运28' },
    { brickCode: 'kralk10', birckName: '韩国幸运10' },
    { brickCode: 'kralk5', birckName: '韩国幸运5' },
    { brickCode: 'azxy10', birckName: '澳洲幸运10' },
    { brickCode: 'azxy5', birckName: '澳洲幸运5' },
    { brickCode: 'more', birckName: '看更多开奖' },
];
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Output() open = new EventEmitter<any>();

    // 默认查询加拿大28
    lotteryCode = 'jndpcdd';
    bricks = bricks;

    constructor(public route: ActivatedRoute, public router: Router) {}
    ngOnInit(): void {
        this.router.navigateByUrl(`/index/${this.lotteryCode}`);
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            const id = params.get('brickId');
        });
        this.open.emit(true);
    }
    // 彩种切换
    changeLottery(code: string) {
        this.lotteryCode = code;
        if (code == 'more') {
            window.open('http://998.fun/');
            return;
        }
        this.router.navigateByUrl(`/index/${this.lotteryCode}`);
    }
}
