<div class="container header">
    <div class="row">
        <div class="pty-header">
            <p class="text-center">提供全面的<em>韩国幸运28</em>相关资讯和<em>加拿大28</em>走势图表</p>
            <a href="javascript:">
                <img class="img-ad" src="../../assets/images/ad.gif" alt="">
            </a>
        </div>
    </div>
    <div class="row brick-list">
        <a (click)="changeLottery(item.brickCode)" *ngFor="let item of bricks;let i = index"
            class="brick-item  col-lg-4 col-md-4 col-sm-4 col-xs-4"
            [ngClass]="{'active':item.brickCode==this.lotteryCode}">
            {{item.birckName}}
        </a>
    </div>

</div>
