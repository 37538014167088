import { ParamMap } from '@angular/router';
import { ConfigService } from 'src/providers/http.service';
import { Injectable } from '@angular/core';
@Injectable()
export class apiService {
    constructor(private http: ConfigService) {}
    // 获取下一期开奖时间
    getNextOpenTime(code: string) {
        let url =
            'api/game-lottery/list-lottery-info-by-condition?code=' + code;
        return this.http.getHttp(url);
    }
    // 查询最近30期开奖号码
    getRecentOpenCode(code: string) {
        let url =
            'api/game-lottery/query-trend?code=' +
            code +
            '&mode=times&screen=30';
        return this.http.getHttp(url);
    }
    // 获取全部玩法最新一期的开奖号码
    getAllOpenCode() {
        let url = 'api/game-lottery/list-lottery-code-by-condition';
        return this.http.getHttp(url);
    }
    // 获取预测内容
    getPredictionList(param: any) {
        let url = 'api/game-lottery/list-lottery-prediction-code';
        return this.http.postHeaers(url, param);
    }
    // 获取遗漏期数
    getMisson(param: any) {
        let url = 'api/game-lottery/list-lottery-skip-hot';
        return this.http.postHeaers(url, param);
    }
}
